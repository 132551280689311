define("discourse/plugins/procourse-static-pages/discourse/routes/admin-plugins-procourse-static-pages", ["exports", "discourse/plugins/procourse-static-pages/discourse/models/page", "discourse/routes/discourse"], function (_exports, _page, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return _page.default.findAll();
    },
    setupController(controller, model) {
      controller.setProperties({
        model
      });
    }
  });
});