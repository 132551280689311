define("discourse/plugins/procourse-static-pages/discourse/models/page-show", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    findById(opts) {
      return (0, _ajax.ajax)(`/page/${opts.id}`);
    }
  };
});