define("discourse/plugins/procourse-static-pages/lib/discourse-markdown/whitelist-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  function setup(helper) {
    helper.allowList(['a.btn', 'a.btn btn-primary', 'a.btn btn-danger', 'button', 'button.btn', 'button.btn btn-primary', 'button.btn btn-danger', 'ul.nav', 'ul.nav-pills', 'ul.nav nav-pills']);
  }
});