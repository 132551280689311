define("discourse/plugins/procourse-static-pages/discourse/procourse-static-pages-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route('procourse-static-pages', {
      path: '/page'
    }, function () {
      this.route('page', {
        path: '/'
      }, function () {
        this.route('show', {
          path: '/:slug/:id'
        });
      });
    });
  }
  ;
});