define("discourse/plugins/procourse-static-pages/discourse/models/page", ["exports", "discourse/lib/ajax", "pretty-text/pretty-text", "discourse/models/group", "@ember/object", "@ember/array", "discourse-common/lib/raw-handlebars", "discourse-common/lib/get-url"], function (_exports, _ajax, _prettyText, _group, _object, _array, _rawHandlebars, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const StaticPage = _object.default.extend({
    init: function () {
      this._super(...arguments);
    }
  });
  function getOpts() {
    const siteSettings = Discourse.__container__.lookup('site-settings:main');
    return (0, _prettyText.buildOptions)({
      getURL: _getUrl.getURLWithCDN,
      currentUser: Discourse.__container__.lookup('current-user:main'),
      siteSettings
    });
  }
  var StaticPages = _object.default.extend({
    selectedItemChanged: (0, _object.observer)('selectedItem', function () {
      var selected = this.get('selectedItem');
      Array(this.get('content')).forEach(i => {
        i.set('selected', selected === i);
      });
    })
  });
  StaticPage.reopenClass({
    findAll: function () {
      var staticPages = Array(StaticPages.create({
        content: [],
        loading: true
      }));
      (0, _ajax.ajax)('/procourse-static-pages/admin/pages.json').then(function (pages) {
        if (pages) {
          pages.forEach(staticPage => {
            var page = JSON.parse(staticPage.value);
            staticPages.pushObject(StaticPage.create({
              ...page
            }));
          });
        }
        staticPages.set('loading', false);
      });
      return staticPages;
    },
    save: function (object) {
      let enabledOnly = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (object.get('disableSave')) return;
      object.set('savingStatus', I18n.t('saving'));
      object.set('saving', true);
      var data = {
        active: object.active
      };
      if (object.id) {
        data.id = object.id;
      }
      if (!object || !enabledOnly) {
        if (object.html) {
          var cooked = "";
        } else {
          var cooked = new _rawHandlebars.Handlebars.SafeString(new _prettyText.default(getOpts()).cook(object.raw));
        }
        data = {
          ...data,
          title: object.title,
          slug: object.slug,
          group: object.group,
          raw: object.raw,
          cooked: cooked.string,
          custom_slug: object.custom_slug,
          html: object.html,
          html_content: object.html_content
        };
      }
      return (0, _ajax.ajax)("/procourse-static-pages/admin/pages.json", {
        data: JSON.stringify({
          "page": data
        }),
        type: object.id ? 'PUT' : 'POST',
        dataType: 'json',
        contentType: 'application/json'
      }).catch(function (result) {
        if (result.jqXHR.responseJSON && result.jqXHR.responseJSON.errors && result.jqXHR.responseJSON.errors[0]) {
          return bootbox.alert(result.jqXHR.responseJSON.errors[0]);
        }
      }).then(function (result) {
        if (result.id) {
          object.set('id', result.id);
          object.set('savingStatus', I18n.t('saved'));
          object.set('saving', false);
        }
      });
    },
    copy: function (object) {
      var copiedPage = StaticPage.create({
        ...object,
        id: null
      });
      return copiedPage;
    },
    destroy: function (object) {
      if (object.id) {
        var data = {
          id: object.id
        };
        return (0, _ajax.ajax)("/procourse-static-pages/admin/pages.json", {
          data: JSON.stringify({
            "page": data
          }),
          type: 'DELETE',
          dataType: 'json',
          contentType: 'application/json'
        });
      }
    },
    customGroups: function () {
      return _group.default.findAll().then(groups => {
        return groups.filter(g => !g.get('automatic'));
      });
    }
  });
  var _default = _exports.default = StaticPage;
});