define("discourse/plugins/procourse-static-pages/discourse/routes/procourse-static-pages-page-show", ["exports", "discourse/plugins/procourse-static-pages/discourse/models/page-show", "discourse/lib/url", "discourse/routes/discourse"], function (_exports, _pageShow, _url, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model(opts) {
      return _pageShow.default.findById(opts);
    },
    titleToken() {
      const model = this.modelFor('procourse-static-pages.page.show');
      if (model && model.title) {
        return model.title;
      }
    },
    setupController(controller, model) {
      controller.setProperties({
        model
      });
    },
    afterModel: function (result) {
      var newURL = `/page/${result.slug}/${result.id}/`;
      _url.default.routeTo(newURL, {
        replaceURL: true
      });
    }
  });
});